// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-listing-page-js": () => import("/opt/build/repo/src/templates/listing-page.js" /* webpackChunkName: "component---src-templates-listing-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-store-notes-js": () => import("/opt/build/repo/src/pages/app-store-notes.js" /* webpackChunkName: "component---src-pages-app-store-notes-js" */),
  "component---src-pages-articles-js": () => import("/opt/build/repo/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

